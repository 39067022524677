import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getPaymentHistoryApi } from "../../apis/apis";
import { trackEvent } from "../../utils/analytics";

const PAYMENT_HISTORY_SLICE_NAME = "PAYMENT_HISTORY_SLICE";

const paymentTypeMap: any = {
  ONE_TIME_PAYMENT: "One-time payment",
  AUTOPAY: "AutoPay",
};

const paymentTypeMethodMap: any = {
  CHECKING: "checking account (ACH)",
  SAVINGS: "savings account (ACH)",
  CREDIT_CARD: "credit card",
  CHECK: "check",
  DIRECT_DEPOSIT: "direct deposit",
};

const transformResponse = (response: any[]) =>
  response.map((pm) => {
    const { type } = pm;
    const [paymentType, paymentTypeMethod] = type.split(" - ");
    return {
      ...pm,
      display:
        pm.providerName === "CHECK"
          ? "Check"
          : pm.providerName === "DIRECT_DEPOSIT"
          ? "Direct deposit"
          : pm.providerName
          ? `${pm.providerName} (••••${pm.last4})`
          : `Delegate user's ${paymentTypeMethodMap[paymentTypeMethod]}`,
      paymentTypeDisplay: paymentTypeMap[paymentType],
    };
  });

export const getPaymentHistory = createAsyncThunk(
  `${PAYMENT_HISTORY_SLICE_NAME}/getPaymentHistory`,
  async (_, { rejectWithValue, getState }) => {
    try {
      trackEvent({ action: "API > Payment History > List" });
      const state: any = getState();
      const { activeTenantAccountId } = state.accountSummary;
      const response = await getPaymentHistoryApi(activeTenantAccountId);
      return transformResponse(response);
    } catch (err) {
      return rejectWithValue({
        err,
      });
    }
  }
);

export const refreshPaymentHistory = createAsyncThunk(
  `${PAYMENT_HISTORY_SLICE_NAME}/refreshPaymentHistory`,
  async (_, { rejectWithValue, getState }) => {
    try {
      const state: any = getState();
      const { activeTenantAccountId } = state.accountSummary;
      const response = await getPaymentHistoryApi(activeTenantAccountId);
      return transformResponse(response);
    } catch (err) {
      return rejectWithValue({
        err,
      });
    }
  }
);

export interface PaymentHistory {
  loading: boolean;
  error: boolean;
  data: Datum[] | [];
}

export interface Datum {
  id: string;
  paymentNumber: string;
  tenantAccountId: string;
  tokenProvider: string;
  status: string;
  amount: number;
  paymentDate: Date;
  postingDate: Date;
  invoiceId: string;
  paymentMethodId: string;
  providerName: string;
  last4: string;
  type: string;
  display: string;
  paymentTypeDisplay: string;
}

const initialState: PaymentHistory = {
  loading: false,
  error: false,
  data: [],
};

const slice = createSlice({
  name: PAYMENT_HISTORY_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPaymentHistory.pending, (state: PaymentHistory) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(
      getPaymentHistory.fulfilled,
      (state: PaymentHistory, { payload }: any) => {
        state.loading = false;
        state.error = false;
        state.data = payload;
      }
    );
    builder.addCase(
      getPaymentHistory.rejected,
      (state: PaymentHistory, { payload }: any) => {
        state.loading = false;
        state.error = payload;
      }
    );

    builder.addCase(
      refreshPaymentHistory.fulfilled,
      (state: PaymentHistory, { payload }: any) => {
        state.loading = false;
        state.error = false;
        state.data = payload;
      }
    );
    builder.addCase(
      refreshPaymentHistory.rejected,
      (state: PaymentHistory, { payload }: any) => {
        state.loading = false;
        state.error = payload;
      }
    );
  },
});

export const { reducer: paymentHistoryReducer } = slice;
