import React from "react";
import { formatCurrency } from "../../../utils/currency";
import { formatUTCDate } from "./../../../utils/string";
import { useAppSelector } from "../../../store/hooks";

interface PropsInterface {
  paymentMethodDisplay?: string;
}

export const AutoPayDebitDetails: React.FC<PropsInterface> = (props) => {
  const { paymentMethodDisplay } = props;
  const accountSummary = useAppSelector((state) => state.accountSummary);
  const paymentMethods = useAppSelector((state) => state.payment.methods);
  const { data, activeTenantAccountIndex } = accountSummary;
  const accountSummaryData = data?.[activeTenantAccountIndex];
  const autopay = accountSummaryData?.autopay
  const { enabled: isAutopayEnabled, owner, paymentMethodId } = autopay;
  const autopayPaymentMethod =
    paymentMethods?.data?.filter((pm: any) => pm.id === paymentMethodId)[0] ||
    {};
  const { defaultPaymentMethod } = paymentMethods;
  const today = new Date();
  const todayMidnightUTC = new Date(
    Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate())
  );

  return (
    <>
      {/* Since next cycle run dates are stored in UTC and have a 00:00:00 time, we need to compare it to the current UTC date with a 00:00:00 time */}
      {new Date(autopay?.nextCycle?.runDate).getTime() >=
        todayMidnightUTC.getTime() &&
        autopay?.nextCycle?.debitAmount > 0 && (
          <>
            <strong>{formatCurrency(autopay?.nextCycle?.debitAmount)}</strong>{" "}
            on or around{" "}
            <strong>{formatUTCDate(autopay?.nextCycle?.runDate)}</strong>
          </>
        )}

      {isAutopayEnabled && !owner ? (
        <> from a delegate user&apos;s payment method</>
      ) : (
        <>
          {" "}
          from payment method{" "}
          <strong style={{ whiteSpace: "nowrap" }}>
            {paymentMethodDisplay ??
              autopayPaymentMethod.display ??
              defaultPaymentMethod?.display}
          </strong>
        </>
      )}
    </>
  );
};
